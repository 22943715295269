import PropTypes from 'prop-types';
import React from 'react';
import { Alert, Button, CheckboxGroup, Checkbox, Icon, RhinoSwitch, UtilityInlineGrid } from 'rhinostyle';

import appleApp from '../assets/images/app-store.svg';
import googleApp from '../assets/images/google-play.svg';
import { GroupHelpers, StringHelpers } from '../helpers';
import { Types } from '../constants';

const UserPreferences = (props) => {
  const {
    allInboxGroupsMobileSelected,
    allInboxGroupsDesktopSelected,
    allChatGroupsMobileSelected,
    allChatGroupsDesktopSelected,
    allInboxAndChatGroupsMobileSelected,
    allInboxAndChatGroupsDesktopSelected,
    browserNotificationPermission,
    chatGroups,
    chatGroupsExpanded,
    chatStaticGroups,
    formInProgress,
    handleFormChanges,
    handleGroupExpandToggle,
    handleGroupToggle,
    handleUseRecommendedSettingsClick,
    handleAllGroupsToggle,
    handleToggle,
    handleTogglePreferences,
    handleUpdateUserPreferences,
    highPriorityNotification,
    userSoundPreferencesEnabled,
    isSoundPreferencesEnabled,
    isBadgeCountUpdatedOnNotification,
    isBadgeCountPreferencesEnabled,
    inboxAndChatGroups,
    inboxAndChatGroupsExpanded,
    inboxCodifiedAppointmentReminderResponseGroups,
    inboxGroups,
    inboxGroupsExpanded,
    inboxStaticGroups,
    isPushNotificationsSupported,
    isNativeApp,
    touchIdEnabled,
    touchIdSupported,
    isLimitedProvider,
    handleClosePreferences,
  } = props;

  const hasInboxGroups = inboxGroups.length > 0;
  const hasChatGroups = chatGroups.length > 0;
  const hasInboxAndChatGroups = inboxAndChatGroups.length > 0;
  const limitedProviderGroupTypes = {
    inbox: ['mentions', 'assigned'],
    chat: ['direct'],
  };

  const renderGroup = (group, index) => {
    if (isLimitedProvider && !limitedProviderGroupTypes[group.groupType].includes(group.type)) {
      return null;
    }
    const groupClass = group.type === 'group' ? 'member-preferences__group-sub' : '';
    const title = `${StringHelpers.capitalize(group.groupType)} ${group.displayName}`;

    return (
      <tr key={index} className={groupClass}>
        <td>
          <strong>{group.displayName}</strong>
        </td>
        <td>
          <CheckboxGroup>
            <Checkbox
              name={`${group.type}_m`}
              title={`${title} Mobile`}
              label="&#8203;"
              isChecked={group.mobile}
              onChange={(name, value) => handleGroupToggle('mobile', value, group)}
            />
          </CheckboxGroup>
        </td>
        <td>
          <CheckboxGroup>
            <Checkbox
              name={`${group.type}_d`}
              label="&#8203;"
              title={`${title} Desktop`}
              isChecked={group.desktop}
              onChange={(name, value) => handleGroupToggle('desktop', value, group)}
            />
          </CheckboxGroup>
        </td>
      </tr>
    );
  };

  const renderTableHeader = (name) => (
    <thead>
      <tr>
        <th>{name} Messages</th>
        <th>
          <Icon className="member-preferences__table__icon" size="large" icon="mobile" />Mobile
        </th>
        <th>
          <Icon className="member-preferences__table__icon" size="large" icon="desktop" />Desktop
        </th>
      </tr>
    </thead>
  );

  return (
    <div className="app-page__container">
      <div className="app-page__container__inner">
        {!isLimitedProvider && (
          <div className="app-page__header">
            <div className="app-page__header__title">My Preferences</div>
          </div>
        )}
        <form onChange={handleFormChanges}>
          <div className="box">
            {!isLimitedProvider && (
              <div className="box__title-wrapper">
                <div className="box__title">Notifications</div>
              </div>
            )}
            {!isNativeApp && !isPushNotificationsSupported && (
              <Alert
                type="info"
                className="u-m-b"
              >
                <Icon bump="up" className="u-m-r-small" icon="info-circle" />Notifications are not supported by this web browser
              </Alert>
            )}
            {!isNativeApp && browserNotificationPermission !== 'granted' && (
              <Alert
                type="info"
                className="u-m-b"
              >
                <Icon bump="up" className="u-m-r-small" icon="info-circle" />Notifications are not enabled for this web browser
              </Alert>
            )}
            <table className="table member-preferences__table">
              {renderTableHeader(GroupHelpers.formatGroupType(Types.TYPE_GROUP_INBOX))}
              <tbody>
                {inboxStaticGroups.map(renderGroup)}
                {!isLimitedProvider && (
                  <tr>
                    {hasInboxGroups ? (
                      <>
                        <td>
                          <strong>All Groups</strong>
                          <br />
                          <Button
                            reset
                            className="u-text-small u-text-primary"
                            data-cypress="viewAllPatientGroups"
                            onClick={() => handleGroupExpandToggle('inboxGroupsExpanded')}
                          >
                            {inboxGroupsExpanded ? 'Hide Individual Groups' : 'Individual Group Notifications'}
                          </Button>
                        </td>
                        {!isLimitedProvider && (
                          <td>
                            <CheckboxGroup>
                              <Checkbox
                                isChecked={allInboxGroupsMobileSelected}
                                onChange={(name, value) =>
                                  handleAllGroupsToggle('inbox', 'mobile', name, value)}
                                name="allInboxGroupsMobileSelected"
                                label="&#8203;"
                              />
                            </CheckboxGroup>
                          </td>
                        )}
                        <td>
                          <CheckboxGroup>
                            <Checkbox
                              isChecked={allInboxGroupsDesktopSelected}
                              onChange={(name, value) =>
                                handleAllGroupsToggle('inbox', 'desktop', name, value)}
                              name="allInboxGroupsDesktopSelected"
                              label="&#8203;"
                            />
                          </CheckboxGroup>
                        </td>
                      </>
                    ) : (
                      <td colSpan={3} className="u-text-muted u-text-small">No {GroupHelpers.formatGroupType(Types.TYPE_GROUP_INBOX)} Groups</td>
                    )}
                  </tr>
                )}

                {inboxGroupsExpanded && inboxGroups.map(renderGroup)}
                {inboxCodifiedAppointmentReminderResponseGroups.map(renderGroup)}
              </tbody>
            </table>
            <table className="table member-preferences__table">
              {renderTableHeader(GroupHelpers.formatGroupType(Types.TYPE_GROUP_CHAT))}
              <tbody>
                {chatStaticGroups.map(renderGroup)}
                {!isLimitedProvider && (
                  <tr>
                    {hasChatGroups ? (
                      <>
                        <td>
                          <strong data-cypress="allGroupsTeam">All Groups</strong>
                          <br />
                          <Button
                            reset
                            className="u-text-small u-text-primary"
                            data-cypress="viewAllTeamGroups"
                            onClick={() => handleGroupExpandToggle('chatGroupsExpanded')}
                          >
                            {chatGroupsExpanded
                              ? 'Hide Individual Groups'
                              : 'Individual Group Notifications'}
                          </Button>
                        </td>
                        {!isLimitedProvider && (
                          <td>
                            <CheckboxGroup>
                              <Checkbox
                                isChecked={allChatGroupsMobileSelected}
                                onChange={(name, value) =>
                                  handleAllGroupsToggle('chat', 'mobile', name, value)}
                                name="allChatGroupsMobileSelected"
                                label="&#8203;"
                              />
                            </CheckboxGroup>
                          </td>
                        )}
                        <td>
                          <CheckboxGroup>
                            <Checkbox
                              isChecked={allChatGroupsDesktopSelected}
                              onChange={(name, value) => handleAllGroupsToggle('chat', 'desktop', name, value)}
                              name="allChatGroupsDesktopSelected"
                              label="&#8203;"
                            />
                          </CheckboxGroup>
                        </td>
                      </>
                    ) : (
                      <td colSpan={3} className="u-text-muted u-text-small">No {GroupHelpers.formatGroupType(Types.TYPE_GROUP_CHAT)} Groups</td>
                    )}
                  </tr>
                )}

                {chatGroupsExpanded && chatGroups.map(renderGroup)}
              </tbody>
            </table>
            {!isLimitedProvider && (
              <table className="table member-preferences__table">
                {renderTableHeader(GroupHelpers.formatGroupType(Types.TYPE_GROUP_INBOX_AND_CHAT))}
                <tbody>
                  <tr>
                    {hasInboxAndChatGroups ? (
                      <>
                        <td>
                          <strong>All Groups</strong>
                          <br />
                          <Button
                            reset
                            className="u-text-small u-text-primary"
                            data-cypress="viewAllPatientTeamGroups"
                            onClick={() => handleGroupExpandToggle('inboxAndChatGroupsExpanded')}
                          >
                            {inboxAndChatGroupsExpanded
                              ? 'Hide Individual Groups'
                              : 'Individual Group Notifications'}
                          </Button>
                        </td>
                        <td>
                          <CheckboxGroup>
                            <Checkbox
                              isChecked={allInboxAndChatGroupsMobileSelected}
                              onChange={(name, value) =>
                                handleAllGroupsToggle('inboxAndChat', 'mobile', name, value)}
                              name="allInboxAndChatGroupsMobileSelected"
                              label="&#8203;"
                            />
                          </CheckboxGroup>
                        </td>
                        <td>
                          <CheckboxGroup>
                            <Checkbox
                              isChecked={allInboxAndChatGroupsDesktopSelected}
                              onChange={(name, value) =>
                                handleAllGroupsToggle('inboxAndChat', 'desktop', name, value)}
                              name="allInboxAndChatGroupsDesktopSelected"
                              label="&#8203;"
                            />
                          </CheckboxGroup>
                        </td>
                      </>
                    ) : (
                      <td colSpan={3} className="u-text-muted u-text-small">No {GroupHelpers.formatGroupType(Types.TYPE_GROUP_INBOX_AND_CHAT)} Groups</td>
                    )}
                  </tr>
                  {inboxAndChatGroupsExpanded && inboxAndChatGroups.map(renderGroup)}
                </tbody>
              </table>
            )}

            {!isLimitedProvider && (
              <div className="u-text-center u-m-t">
                <Button
                  reset
                  className="u-text-underline"
                  onClick={handleUseRecommendedSettingsClick}
                >
                  Use recommended settings for all notifications
                </Button>
              </div>
            )}
          </div>
          <div className="box">
            <div className="box__title-wrapper">
              <div className="box__title">NOTIFICATION OPTIONS</div>
            </div>
            {isSoundPreferencesEnabled && (
              <div className="block-preference">
                <div className="block-preference__left">
                  <h4 className="block-preference__name">Sound</h4>
                  <div className="block-preference__desc">
                    ON: Receive sound notifications for all groups and inboxes that you are a part of.
                  </div>
                  <div className="block-preference__desc">
                    OFF: Receive sound notifications for only the groups and inboxes that you have checked in the notifications table above.
                  </div>
                </div>
                <div className="block-preference__right">
                  <RhinoSwitch
                    isChecked={userSoundPreferencesEnabled}
                    onChange={handleTogglePreferences}
                    name="userSoundPreferencesEnabled"
                    dataFeatureTag="userSoundPreferencesEnabled"
                  />
                </div>
              </div>
            )}
            {isBadgeCountPreferencesEnabled && (
              <div className="block-preference">
                <div className="block-preference__left">
                  <h4 className="block-preference__name">Badge Count</h4>
                  <div className="block-preference__desc">
                    ON: Receive badge counts for all groups and inboxes that you are a part of.
                  </div>
                  <div className="block-preference__desc">
                    OFF: Receive badge counts for only the groups and inboxes that you have checked in the notifications table above.
                  </div>
                </div>
                <div className="block-preference__right">
                  <RhinoSwitch
                    isChecked={isBadgeCountUpdatedOnNotification}
                    onChange={handleTogglePreferences}
                    name="isBadgeCountUpdatedOnNotification"
                    dataFeatureTag="isBadgeCountUpdatedOnNotification"
                  />
                </div>
              </div>
            )}
            {!isLimitedProvider && (
              <div className="block-preference">
                <div className="block-preference__left">
                  <h4 className="block-preference__name">Mobile Alerts</h4>
                  <div className="block-preference__desc">
                    Use a more prominent sound and duration to alert you of message notifications on your mobile device.
                  </div>
                </div>
                <div className="block-preference__right">
                  <RhinoSwitch
                    isChecked={highPriorityNotification}
                    onChange={handleTogglePreferences}
                    name="highPriorityNotification"
                    dataFeatureTag="highPriorityNotification"
                  />
                </div>
              </div>
            )}
          </div>
          {!isLimitedProvider && touchIdSupported && (
            <div className="box">
              <div className="box__title-wrapper">
                <div className="box__title">
                  <Icon className="box__touchid-icon" icon="touchid" />Log In Using Touch ID / Face ID
                </div>
                <div className="box__subtitle">
                  Use Apple Touch ID / Face ID to log in using your fingerprint or facial recognition instead of entering your email and password.
                </div>
              </div>
              <div className="block-preference">
                <div className="block-preference__left">
                  <h4 className="block-preference__name">Enable Touch ID / Face ID</h4>
                  <div className="block-preference__desc">
                    All users with a Touch ID / Face ID profile on this device will have access to your
                    Rhinogram app.
                  </div>
                </div>
                <div className="block-preference__right">
                  <RhinoSwitch isChecked={touchIdEnabled} onChange={handleToggle} name="touchId" />
                </div>
              </div>
            </div>
          )}
          <UtilityInlineGrid align="right">
            <div className="u-m-t-large u-text-right">
              <Button type="default" onClick={handleClosePreferences} data-cypress="closePreference">
                Cancel
              </Button>
            </div>
            <div className="u-m-t-large u-text-right">
              <Button loading={formInProgress} type="primary" onClick={handleUpdateUserPreferences} data-cypress="updatePreference">
                Update Preferences
              </Button>
            </div>
          </UtilityInlineGrid>
        </form>
        {!isLimitedProvider && !isNativeApp && (
          <div className="box u-m-t-large">
            <div className="box__title-wrapper">
              <div className="box__title">
                Download the Rhinogram App
              </div>
              <div className="box__subtitle">
                Stay connected outside the office. Get the Rhinogram app on your mobile device.
              </div>
            </div>
            <UtilityInlineGrid align="middle">
              <a href="https://itunes.apple.com/us/app/rhinogram/id1256043051?mt=8" target="_blank" rel="noopener noreferrer">
                <img
                  className="member-preferences__app-svg"
                  src={appleApp}
                  alt="Download on the App Store"
                />
              </a>
              <a href="https://play.google.com/store/apps/details?id=com.rhinonative" target="_blank" rel="noopener noreferrer">
                <img
                  className="member-preferences__app-svg"
                  src={googleApp}
                  alt="Get it on Google Play"
                />
              </a>
            </UtilityInlineGrid>
          </div>
        )}
      </div>
    </div>
  );
};

UserPreferences.propTypes = {
  allChatGroupsDesktopSelected: PropTypes.bool.isRequired,
  allChatGroupsMobileSelected: PropTypes.bool.isRequired,
  allInboxAndChatGroupsDesktopSelected: PropTypes.bool.isRequired,
  allInboxAndChatGroupsMobileSelected: PropTypes.bool.isRequired,
  allInboxGroupsDesktopSelected: PropTypes.bool.isRequired,
  allInboxGroupsMobileSelected: PropTypes.bool.isRequired,
  browserNotificationPermission: PropTypes.string.isRequired,
  chatGroups: PropTypes.array.isRequired,
  chatGroupsExpanded: PropTypes.bool.isRequired,
  chatStaticGroups: PropTypes.array.isRequired,
  formInProgress: PropTypes.bool,
  handleAllGroupsToggle: PropTypes.func.isRequired,
  handleGroupExpandToggle: PropTypes.func.isRequired,
  handleGroupToggle: PropTypes.func.isRequired,
  handleFormChanges: PropTypes.func.isRequired,
  handleToggle: PropTypes.func,
  handleTogglePreferences: PropTypes.func.isRequired,
  handleUpdateUserPreferences: PropTypes.func.isRequired,
  handleUseRecommendedSettingsClick: PropTypes.func.isRequired,
  highPriorityNotification: PropTypes.bool.isRequired,
  userSoundPreferencesEnabled: PropTypes.bool.isRequired,
  inboxAndChatGroups: PropTypes.array.isRequired,
  inboxAndChatGroupsExpanded: PropTypes.bool.isRequired,
  inboxCodifiedAppointmentReminderResponseGroups: PropTypes.array.isRequired,
  inboxGroups: PropTypes.array.isRequired,
  inboxGroupsExpanded: PropTypes.bool.isRequired,
  inboxStaticGroups: PropTypes.array.isRequired,
  isNativeApp: PropTypes.bool.isRequired,
  isPushNotificationsSupported: PropTypes.bool.isRequired,
  touchIdEnabled: PropTypes.bool,
  touchIdSupported: PropTypes.bool,
  isSoundPreferencesEnabled: PropTypes.bool,
  isBadgeCountPreferencesEnabled: PropTypes.bool,
  isBadgeCountUpdatedOnNotification: PropTypes.bool,
  isLimitedProvider: PropTypes.bool,
  handleClosePreferences: PropTypes.func,
};

export default UserPreferences;
