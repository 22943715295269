import { createSlice } from '@reduxjs/toolkit';
import { normalize } from 'normalizr';

import { compareObjectByKey, getDefaultSortKey, getObjectKeys } from '../helpers/DataHelpers';
import * as RhinoformService from '../services/RhinoformService';
import NotificationService from '../services/NotificationService';
import { receiveUserForms } from './userReducer';
import { FORMS_LIST_HEADERS_CONFIG } from '../constants/LibraryConstants';
import { JOTFORM_COOKIES } from '../constants/AppConstants';
import { contactForm } from '../actions/NormalizrSchema';
import formSlice from '../services/formService'; // eslint-disable-line

// SLICE
const paySlice = createSlice({
  name: 'FORM',
  initialState: {
    org: {},
    formTemplates: [],
    allForms: [],
    count: 0,
    pageLoading: false,
    isOrgFormInProgress: false,
    isOrgLoading: false,
    isFormTemplateDeleting: false,
    form: {},
    isFormLoading: false,
    isFormTemplateUpdating: false,
    formS3Url: {},
    isPdfLoading: false,
    isSaveDraftFormLoading: false,
    isSaveFormLoading: false,
    contactForms: [],
    otherUserForms: {},
    formUploadPostProcessing: [],
    formTitles: [],
    formTitlesLoading: false,
    pageNumber: 0,
    totalContactFormCount: 0,
    contactFormIds: [],
    contactFormsLoading: false,
    formManagerLoading: false,
    isFormSending: false,
  },
  reducers: {
    receiveFormOrganization: (state, action) => ({
      ...state,
      org: {
        ...state.org,
        ...action.payload.org,
      },
      isOrgLoading: action.payload.isOrgLoading,
      isOrgFormInProgress: action.payload.isOrgFormInProgress,
    }),
    receiveFormUrl: (state, action) => ({
      ...state,
      form: {
        ...state.form,
        ...action.payload.form,
      },
      isFormLoading: action.payload.isFormLoading,
      isSaveDraftFormLoading: false,
      isSaveFormLoading: false,
      pageLoading: false,
    }),
    removeForm: (state) => ({
      ...state,
      form: {},
      isFormLoading: false,
    }),
    requestFormOrganization: (state) => ({
      ...state,
      isOrgLoading: true,
    }),
    setFormOrganizationInProgress: (state) => ({
      ...state,
      isFormInProgress: true,
    }),
    setFormInProgress: (state) => ({
      ...state,
      isFormLoading: true,
      pageLoading: true,
    }),
    setFormUploadPostProcessingInProgress: (state, action) => ({
      ...state,
      isFormLoading: true,
      formUploadPostProcessing: [...state.formUploadPostProcessing, action.payload.key],
    }),
    receiveWebSocketFormUploadPostProcessing: (state, action) => ({
      ...state,
      isFormLoading: false,
      formUploadPostProcessing: state.formUploadPostProcessing.filter((key) => key !== action.payload.key),
      form: {
        ...state.form,
        ...action.payload,
      },
      isSaveDraftFormLoading: false,
      isSaveFormLoading: false,
    }),
    receiveFormOrganizationError: (state) => ({
      ...state,
      org: {},
      isOrgFormInProgress: false,
      isOrgLoading: false,
    }),
    receiveFormUrlError: (state) => ({
      ...state,
      form: {},
      isFormLoading: false,
      isPdfLoading: false,
      pageLoading: false,
    }),
    receiveUpdatedFormTemplate: (state, action) => ({
      ...state,
      formTemplates: updateFormTemplate(state, action),
      allForms: state.allForms.map((form) => {
        if (form.formId === action.payload.formId) {
          return {
            ...form,
            updatedAt: action.payload.updatedAt,
            lastModifiedBy: action.payload.keyToUpdate === 'isPublished' ? action.payload.lastModifiedBy : form.lastModifiedBy,
            [action.payload.keyToUpdate]: !form[action.payload.keyToUpdate],
          };
        }
        return form;
      }),
      count: action.payload.selectedFormFilter === 'favorite' && action.payload.keyToUpdate === 'isFavorite' ? state.count - 1 : state.count,
      isFormTemplateUpdating: false,
    }),
    receiveFormTemplates: (state, action) => {
      let allForms = action.payload.allForms ? [...action.payload.allForms] : state.allForms;
      let formTemplates = [...action.payload.formTemplates];
      if (action.payload.allForms) {
        const defaultSortKey = getDefaultSortKey(FORMS_LIST_HEADERS_CONFIG);
        const favoriteForms = action.payload.formTemplates.filter((form) => form.isFavorite);
        const nonFavoriteForms = action.payload.formTemplates.filter((form) => !form.isFavorite);
        const sortedFavorites = favoriteForms.sort((a, b) => compareObjectByKey(a, b, defaultSortKey));
        const sortedNonFavorites = nonFavoriteForms.sort((a, b) => compareObjectByKey(a, b, defaultSortKey));
        formTemplates = [...sortedFavorites, ...sortedNonFavorites];
        allForms = formTemplates;
      }
      return {
        ...state,
        formTemplates,
        allForms,
        count: action.payload.formTemplates.length,
        pageLoading: false,
      };
    },
    requestFormTemplates: (state) => ({
      ...state,
      pageLoading: true,
    }),
    receiveFormTemplatesError: (state) => ({
      ...state,
      formTemplates: [],
      count: 0,
      pageLoading: false,
    }),
    receiveUpdatedFormsList: (state, action) => ({
      ...state,
      formTemplates: action.payload.formTemplates,
      count: action.payload.count,
      pageLoading: action.payload.pageLoading,
    }),
    requestDeleteFormTemplate: (state) => ({
      ...state,
      isFormTemplateDeleting: true,
    }),
    requestSendForm: (state) => ({
      ...state,
      isFormSending: true,
    }),
    receiveSendFormResponse: (state) => ({
      ...state,
      isFormSending: false,
    }),
    requestUpdateFormTemplate: (state) => ({
      ...state,
      isFormTemplateUpdating: true,
    }),
    removeFormTemplate: (state, action) => ({
      ...state,
      formTemplates: deleteFormTemplatePayload(state, action),
      allForms: state.allForms.filter((form) => form.formId !== action.payload),
      count: state.count - 1,
      isFormTemplateDeleting: false,
    }),
    clearFormTemplates: (state) => ({
      ...state,
      formTemplates: [],
      count: 0,
      pageLoading: true,
    }),
    receiveFormS3Url: (state, action) => ({
      ...state,
      formS3Url: {
        ...state.formS3Url,
        [action.payload._id]: action.payload,
        isPdfLoading: false,
      },
      otherUserForms: {
        [action.payload._id]: action.payload,
      },
    }),
    setPdfLoadingInProgress: (state) => ({
      ...state,
      formS3Url: {
        ...state.formS3Url,
        isPdfLoading: true,
      },
    }),
    setFormLoadingInProgress: (state, action) => ({
      ...state,
      isFormLoading: true,
      isSaveFormLoading: action.payload,
      isSaveDraftFormLoading: !action.payload,
    }),
    receiveContactForms: (state, action) => ({
      ...state,
      contactForms: {
        ...state.contactForms,
        ...action.payload.contactForms,
      },
      totalContactFormCount: action.payload.totalContactFormCount,
      pageNumber: action.payload.pageNumber,
      contactFormIds: action.payload.contactFormIds,
      contactFormsLoading: false,
    }),
    requestContactForms: (state) => ({
      ...state,
      contactFormsLoading: true,
    }),
    requestFormTitles: (state) => {
      state.formTitlesLoading = false;
    },
    receiveFormTitles: (state, action) => {
      state.formTitles = action.payload || [];
      state.formTitlesLoading = false;
    },
  },
});

export default paySlice.reducer;

// ACTIONS
export const {
  receiveFormOrganization,
  requestFormOrganization,
  setFormOrganizationInProgress,
  receiveFormOrganizationError,
  receiveFormTemplates,
  requestFormTemplates,
  receiveFormTemplatesError,
  receiveUpdatedFormTemplate,
  receiveUpdatedFormsList,
  requestDeleteFormTemplate,
  clearFormTemplates,
  removeFormTemplate,
  receiveFormUrl,
  receiveFormUrlError,
  removeForm,
  setFormInProgress,
  setPdfFormProcess,
  requestUpdateFormTemplate,
  receiveFormS3Url,
  receiveFormS3UrlError,
  setPdfLoadingInProgress,
  setFormLoadingInProgress,
  setContactFormLoadingInProgress,
  requestContactForms,
  receiveContactForms,
  setFormUploadPostProcessingInProgress,
  receiveWebSocketFormUploadPostProcessing,
  requestFormTitles,
  receiveFormTitles,
  requestSendForm,
  receiveSendFormResponse,
} = paySlice.actions;

export function fetchOrgConfiguration(orgId) {
  return (dispatch) => {
    dispatch(requestFormOrganization());
    return RhinoformService.getOrganization(orgId)
      .then((response) => {
        dispatch(receiveFormOrganization(prepareFormPayload(response)));
      })
      .catch((err) => {
        console.error(err);
        dispatch(receiveFormOrganizationError());
      });
  };
}

export function createOrgConfiguration(payload) {
  return (dispatch) => {
    dispatch(setFormOrganizationInProgress());
    return RhinoformService.postOrganization(payload)
      .then((response) => {
        dispatch(receiveFormOrganization(prepareFormPayload(response)));
        NotificationService('createFormOrganizationConfiguration', response);
      })
      .catch((err) => {
        console.error(err);
        dispatch(receiveFormOrganizationError());
        NotificationService('createFormOrganizationConfiguration', err.response);
      });
  };
}

export function updateOrgConfiguration(payload) {
  return (dispatch) => {
    dispatch(setFormOrganizationInProgress());
    return RhinoformService.patchOrganization(payload)
      .then((response) => {
        dispatch(receiveFormOrganization(prepareFormPayload(response)));
        NotificationService('updateFormOrganizationConfiguration', response);
      })
      .catch((err) => {
        console.error(err);
        dispatch(receiveFormOrganizationError());
        NotificationService('updateFormOrganizationConfiguration', err.response);
      });
  };
}

export function fetchFormTemplates(filter = 'all', isPublished = false) {
  return (dispatch) => {
    dispatch(requestFormTemplates());
    dispatch(clearFormTemplates());
    return RhinoformService.getFormTemplates(isPublished)
      .then((response) => {
        dispatch(receiveFormTemplates(prepareFormsTemplatePayload(response.data?.forms, filter)));
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err);
        dispatch(receiveFormTemplatesError());
        NotificationService('fetchFormTemplates', err.response);
      });
  };
}

export function fetchFormManagerView(params) {
  return (dispatch) => {
    dispatch(requestContactForms());
    dispatch(fetchFormTitles());
    dispatch(getContactForms(params));
  };
}

export function fetchFormTitles() {
  return async (dispatch) => {
    dispatch(requestFormTitles());
    const response = await RhinoformService.getFormTitles();
    dispatch(receiveFormTitles(response.data));
  };
}

export function fetchContactForms(contactId) {
  return (dispatch) => RhinoformService.getContactForms(contactId)
    .then((response) => {
      const users = {
        [contactId]: {
          forms: response.data?.forms,
        },
      };
      dispatch(receiveUserForms({ users }));
      dispatch(receiveSendFormResponse());
    })
    .catch((err) => {
      // eslint-disable-next-line no-console
      console.error(err);
    });
}

export function updateFormsList(formTemplates) {
  return (dispatch) => {
    dispatch(clearFormTemplates());
    dispatch(receiveFormTemplates(prepareFormsTemplatePayload(formTemplates)));
  };
}

export function updateFormTemplates(formId, payload, selectedFormFilter = '') {
  return (dispatch) => {
    if (payload.isPublished) dispatch(requestUpdateFormTemplate());
    return RhinoformService.patchFormTemplates(formId, payload)
      .then((response) => {
        let responsePayload = {};
        if (payload.isPublished) {
          responsePayload = {
            ...response.data,
            selectedFormFilter,
            keyToUpdate: 'isPublished',
          };
          NotificationService('formTemplatePublished', response);
        } else {
          responsePayload = {
            ...response.data,
            selectedFormFilter,
            keyToUpdate: 'isFavorite',
          };
        }
        dispatch(receiveUpdatedFormTemplate(responsePayload));
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err);
        if (payload.isPublished) {
          NotificationService('formTemplatePublished', err.response);
        } else {
          NotificationService('updateFormTemplate', err.response);
        }
      });
  };
}

export function refetchContactForms() {
  return (dispatch) => {
    dispatch(formSlice.util.invalidateTags(['UserForms']));
  };
}

export function sendFormById(formId, payload, created = false) {
  return (dispatch) => {
    dispatch(requestSendForm());
    return RhinoformService.sendFormById(formId, payload, created)
      .then(() => {
        dispatch(formSlice.util.invalidateTags(['UserForms']));
        dispatch(receiveSendFormResponse());
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err);
      });
  };
}

export function deleteFormTemplate(formId, selectedFormFilter) {
  return (dispatch) => {
    dispatch(requestDeleteFormTemplate());
    return RhinoformService.deleteFormTemplate(formId)
      .then((response) => {
        dispatch(removeFormTemplate(formId, selectedFormFilter));
        NotificationService('deleteFormTemplate', response);
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err);
        NotificationService('deleteFormTemplate', err.response);
      });
  };
}

export function prepareFormPayload(Org) {
  return {
    org: {
      isRhinoformEnabled: !!Org.data?.isEnabled,
      username: Org.data?.username,
      seatsLimit: Org.data?.memberSeats,
      email: Org.data?.email,
    },
    isOrgLoading: false,
    isOrgFormInProgress: false,
  };
}

export function shapeFormUrlPayload(form) {
  return {
    form: {
      iFrameUrl: form.data?.iFrameUrl,
      formExternalId: form.data?.formExternalId,
      title: form.data?.title,
      formId: form.data?.formId,
      formUrl: form.data?.formUrl,
      version: form.data?.version,
      isPdf: form.data?.isPdf,
      favorites: form.data?.favorites,
      pdfFileName: form.data?.pdfFileName,
      sourceId: form.data?.sourceId,
      enableSmartPdf: !!form.data?.enableSmartPdf,
      autoSendToEHR: form.data?.autoSendToEHR,
      isConsentForm: form.data?.isConsentForm,
      consentFormId: form.data?.consentFormId,
      formTemplateOfficeId: form.data?.formTemplateOfficeId,
    },
    isFormLoading: false,
  };
}

export function getAuthenticateOrgUser(payload, type, formData, config, isFormEditableMode) {
  return (dispatch) => {
    dispatch(setFormInProgress());
    return authenticatePage()
      .then(() => {
        if (isFormEditableMode) {
          return dispatch(cloneRhinoform(payload));
        } else {
          return this.buildRhinoform(payload, type, formData, config);
        }
      })
      .catch((err) => {
        dispatch(receiveFormUrlError());
        console.error(err);
        dispatch(receiveFormUrlError());
        NotificationService('rhinoformCreated', err.response);
      });
  };
}

export function authenticatePage() {
  return RhinoformService.getAuthenticatedUser()
    .then((response) => {
      if (!process.env.REACT_APP_ENVIRONMENT) {
        removeJotformCookies();
      }
      response.data.forEach((cookie) => {
        document.cookie = cookie;
      });
    })
    .catch((err) => {
      console.error(err);
      NotificationService('rhinoformCreated', err.response);
    });
}

// RHIN-6885 sometime jotform cookies are messed and page not authenticated so clearing cookies everytime before adding.
function removeJotformCookies() {
  const url = process.env.REACT_APP_URL;
  const domain = url.substr(url.indexOf('.'));
  const cookies = document.cookie.split(';');
  for (let i = 0; i < cookies.length; i++) {
    const key = cookies[i].split('=')[0].trim();
    if (JOTFORM_COOKIES.includes(key)) {
      document.cookie = `${key}=; expires=${new Date(0).toUTCString()}; domain=${domain}; path=/`;
    }
  }
}

export function deleteRhinoform(payload) {
  return (dispatch) =>
    RhinoformService.deleteRhinoform(payload)
      .then((response) => {
        dispatch(removeForm(response));
      })
      .catch((err) => {
        console.error(err);
      });
}

export function createRhinoForm(payload) {
  return (dispatch) => {
    const notificationType = payload.isEditMode ? 'rhinoFormUpdated' : 'rhinoformCreated';
    dispatch(setFormLoadingInProgress(payload.isPublished));
    return RhinoformService.createRhinoform(payload)
      .then((response) => {
        dispatch(receiveFormUrl(shapeFormUrlPayload(response)));
        NotificationService(notificationType, response);
      })
      .catch((err) => {
        console.error(err);
        dispatch(receiveFormUrlError());
        NotificationService('rhinoformCreated', err.response);
      });
  };
}

export function buildRhinoform(payload, type, formData, config, isUpdate) {
  return (dispatch) => {
    dispatch(setFormInProgress());
    return RhinoformService.buildFormTemplate(payload, type, formData, config, isUpdate)
      .then((response) => {
        dispatch(receiveFormUrl(shapeFormUrlPayload(response)));
      })
      .catch((err) => {
        console.error(err);
        dispatch(receiveFormUrlError());
        NotificationService('rhinoformCreated', err.response);
      });
  };
}

export function uploadPresignPDF(payload) {
  return (dispatch) => {
    dispatch(setFormUploadPostProcessingInProgress(payload));
    return RhinoformService.postPresignPDF(payload)
      .catch((err) => {
        console.error(err);
        dispatch(receiveFormUrlError());
        NotificationService('rhinoformCreated', err.response);
      });
  };
}

export function prepareFormsTemplatePayload(Forms, filter) {
  const formsPayload = {
    formTemplates: [...Forms],
  };

  if (filter === 'all') {
    formsPayload.allForms = [...Forms];
  }
  return formsPayload;
}

export function prepareUpdateFormsListPayload(formTemplates) {
  return {
    formTemplates,
    count: formTemplates?.length,
    pageLoading: false,
  };
}

function deleteFormTemplatePayload(state, action) {
  const formTemplates = [...state.allForms];
  const filteredForm = formTemplates.filter((form) => form.formId !== action.payload);
  return filteredForm;
}

function updateFormTemplate(state, action) {
  const { selectedFormFilter, keyToUpdate, ...formPayload } = action.payload;
  const formTemplates = [...state.formTemplates];
  let updatedForms = [];
  if (selectedFormFilter === 'all') {
    updatedForms = formTemplates.map((form) => {
      if (form.formId === formPayload.formId) {
        return {
          ...formPayload,
          lastModifiedBy: form.lastModifiedBy,
        };
      }
      return form;
    });
  } else if (selectedFormFilter === 'favorite' && keyToUpdate === 'isFavorite') {
    updatedForms = formTemplates.filter((form) => form.formId !== formPayload.formId);
  }
  return updatedForms;
}

export function cloneRhinoform(payload) {
  return (dispatch) => {
    dispatch(setFormInProgress());
    // dispatch(removeForm());
    return RhinoformService.cloneFormTemplate(payload.formId, payload)
      .then((response) => {
        dispatch(receiveFormUrl(shapeFormUrlPayload(response)));
      })
      .catch((err) => {
        console.error(err);
        dispatch(receiveFormUrlError());
        NotificationService('rhinoFormUpdated', err.response);
      });
  };
}

export function getFormTemplateById(formId) {
  return (dispatch) => {
    dispatch(setPdfLoadingInProgress());
    return RhinoformService.getFormTemplateById(formId)
      .then((response) => {
        dispatch(receiveFormS3Url(response.data));
      })
      .catch((err) => {
        console.error(err);
        dispatch(receiveFormUrlError());
      });
  };
}

export function getFormById(formId) {
  return (dispatch) => RhinoformService.getFormById(formId)
    .then((response) => {
      dispatch(receiveFormS3Url(response.data));
    })
    .catch((err) => {
      console.error(err);
      dispatch(receiveFormUrlError());
    });
}

export function getContactForms(params) {
  return (dispatch) => {
    dispatch(requestContactForms());
    return RhinoformService.getPatientForms(params)
      .then((response) => {
        const normalized = normalize(response.data.forms, [contactForm]);
        dispatch(receiveContactForms(shapeContactFormsPayload(normalized, response.data, params.pageNumber)));
      })
      .catch((err) => {
        console.error(err);
        dispatch(receiveFormUrlError());
      });
  };
}

function shapeContactFormsPayload(normalizedForms, formsResult, pageNumber = 0) {
  return {
    contactForms: {
      ...normalizedForms.entities.contactForm,
    },
    contactFormIds: normalizedForms.result,
    totalContactFormCount: formsResult.count,
    pageNumber: pageNumber || 0,
    users: normalizedForms.entities.users,
    userIds: getObjectKeys(normalizedForms.entities.users),
    phones: {
      ...normalizedForms.entities.phones,
    },
    phoneIds: getObjectKeys(normalizedForms.entities.phones),
    emails: {
      ...normalizedForms.entities.emails,
    },
    emailIds: getObjectKeys(normalizedForms.entities.emails),
    tags: {
      ...normalizedForms.entities.tags,
    },
    tagIds: getObjectKeys(normalizedForms.entities.tags),
  };
}
