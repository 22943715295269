import * as UserPermissionsConstants from './UserPermissionsConstants';
// An empty permissions implies that that permission does not exist in the system yet. It's parent object is still needed for proper rendering in the UI

export const organizationActionsTemplate = [
  {
    name: 'Appointment Data - Types',
    options: [
      { action: 'delete', permissions: [] },
      { action: 'edit', permissions: [UserPermissionsConstants.APPOINTMENT_TYPE_EDIT] },
      { action: 'view', permissions: [UserPermissionsConstants.APPOINTMENT_TYPE_VIEW] },
    ],
  },
  {
    name: 'Appointment Manager',
    options: [
      { action: 'delete', permissions: [] },
      { action: 'edit', permissions: [] },
      { action: 'view', permissions: [UserPermissionsConstants.APPOINTMENT_MANAGER_VIEW] },
    ],
  },
  {
    name: 'Appointment Reminders',
    options: [
      { action: 'delete', permissions: [UserPermissionsConstants.AUTOMATED_MESSAGE_DELETE] },
      { action: 'edit', permissions: [UserPermissionsConstants.AUTOMATED_MESSAGE_EDIT] },
      { action: 'view', permissions: [UserPermissionsConstants.AUTOMATED_MESSAGE_VIEW] },
    ],
  },
  {
    name: 'Prescription Data',
    options: [
      { action: 'delete', permissions: [] },
      { action: 'edit', permissions: [UserPermissionsConstants.PRESCRIPTION_DATA_EDIT] },
      { action: 'view', permissions: [UserPermissionsConstants.PRESCRIPTION_DATA_VIEW] },
    ],
    visible(props) {
      return props?.activeOrg?.arePrescriptionCampaignsEnabled || props?.activeOrg?.isPrescriptionManagerEnabled;
    },
  },
  {
    name: 'Prescription Manager',
    options: [
      { action: 'delete', permissions: [] },
      { action: 'edit', permissions: [] },
      { action: 'view', permissions: [UserPermissionsConstants.PRESCRIPTION_MANAGER_VIEW] },
    ],
    visible(props) {
      return props?.activeOrg?.arePrescriptionCampaignsEnabled || props?.activeOrg?.isPrescriptionManagerEnabled;
    },
  },
  {
    name: 'Prescription Notifications',
    options: [
      { action: 'delete', permissions: [UserPermissionsConstants.PRESCRIPTION_NOTIFICATION_DELETE] },
      { action: 'edit', permissions: [UserPermissionsConstants.PRESCRIPTION_NOTIFICATION_EDIT] },
      { action: 'view', permissions: [UserPermissionsConstants.PRESCRIPTION_NOTIFICATION_VIEW] },
    ],
    visible(props) {
      return props?.activeOrg?.arePrescriptionCampaignsEnabled || props?.activeOrg?.isPrescriptionManagerEnabled;
    },
  },
  {
    name: 'Audit Log',
    options: [
      { action: 'delete', permissions: [] },
      { action: 'edit', permissions: [] },
      { action: 'view', permissions: [UserPermissionsConstants.AUDIT_LOG_VIEW] },
    ],
  },
  {
    name: 'Billing',
    options: [
      { action: 'delete', permissions: [] },
      { action: 'edit', permissions: [UserPermissionsConstants.BILLING_EDIT] },
      { action: 'view', permissions: [UserPermissionsConstants.BILLING_VIEW] },
    ],
  },
  {
    name: 'Channels',
    options: [
      { action: 'delete', permissions: [] },
      {
        action: 'edit',
        permissions: [UserPermissionsConstants.CHANNEL_EDIT,
          UserPermissionsConstants.CHANNEL_SECURE_CREATE,
          UserPermissionsConstants.CHANNEL_FACEBOOK_CREATE],
      },
      { action: 'view', permissions: [UserPermissionsConstants.CHANNEL_VIEW] },
    ],
  },
  {
    name: 'Groups',
    options: [
      { action: 'delete', permissions: [UserPermissionsConstants.GROUP_DELETE] },
      { action: 'edit', permissions: [UserPermissionsConstants.GROUP_CHAT_EDIT, UserPermissionsConstants.GROUP_INBOX_EDIT] },
      { action: 'view', permissions: [UserPermissionsConstants.GROUP_VIEW] },
    ],
  },
  {
    name: 'Members',
    options: [
      { action: 'delete', permissions: [UserPermissionsConstants.MEMBER_DELETE] },
      { action: 'edit', permissions: [UserPermissionsConstants.MEMBER_EDIT, UserPermissionsConstants.MEMBER_CREATE, UserPermissionsConstants.MEMBER_PASSWORD_CREATE] },
      { action: 'view', permissions: [UserPermissionsConstants.MEMBER_VIEW] },
    ],
  },
  {
    name: 'Integrated Contacts',
    options: [
      { action: 'delete', permissions: [] },
      { action: 'edit', permissions: [UserPermissionsConstants.INTEGRATED_CONTACTS_EDIT] },
      { action: 'view', permissions: [] },
    ],
  },
  {
    name: 'Offices',
    options: [
      { action: 'delete', permissions: [UserPermissionsConstants.OFFICE_DELETE] },
      { action: 'edit', permissions: [UserPermissionsConstants.OFFICE_EDIT, UserPermissionsConstants.OFFICE_CREATE] },
      { action: 'view', permissions: [UserPermissionsConstants.OFFICE_VIEW] },
    ],
  },
  {
    name: 'Out of Office',
    options: [
      { action: 'delete', permissions: [UserPermissionsConstants.OUT_OF_OFFICE_DELETE] },
      { action: 'edit', permissions: [UserPermissionsConstants.OUT_OF_OFFICE_EDIT, UserPermissionsConstants.OUT_OF_OFFICE_CREATE] },
      { action: 'view', permissions: [UserPermissionsConstants.OUT_OF_OFFICE_VIEW] },
    ],
  },
  {
    name: 'Preferences',
    options: [
      { action: 'delete', permissions: [] },
      { action: 'edit', permissions: [UserPermissionsConstants.ORGANIZATION_PREFERENCES_EDIT] },
      { action: 'view', permissions: [UserPermissionsConstants.ORGANIZATION_PREFERENCES_VIEW] },
    ],
  },
  {
    name: 'Profile',
    options: [
      { action: 'delete', permissions: [] },
      { action: 'edit', permissions: [UserPermissionsConstants.ORGANIZATION_PROFILE_EDIT] },
      { action: 'view', permissions: [UserPermissionsConstants.ORGANIZATION_PROFILE_VIEW] },
    ],
  },
  {
    name: 'RhinoForms',
    options: [
      { action: 'delete', permissions: [UserPermissionsConstants.FORM_DELETE] },
      { action: 'edit', permissions: [UserPermissionsConstants.FORM_EDIT] },
      { action: 'view', permissions: [UserPermissionsConstants.FORM_VIEW] },
    ],
  },
  {
    name: 'RhinoPay Manager',
    options: [
      { action: 'delete', permissions: [] },
      { action: 'edit', permissions: [] },
      { action: 'view', permissions: [UserPermissionsConstants.RHINOPAY_MANAGER_VIEW] },
    ],
  },
  {
    name: 'Roles',
    options: [
      { action: 'delete', permissions: [UserPermissionsConstants.ROLE_DELETE] },
      { action: 'edit', permissions: [UserPermissionsConstants.ROLE_EDIT, UserPermissionsConstants.ROLE_CREATE] },
      { action: 'view', permissions: [UserPermissionsConstants.ROLE_VIEW] },
    ],
  },
  {
    name: 'Routing Manager',
    options: [
      { action: 'delete', permissions: [UserPermissionsConstants.ROUTING_MANAGER_DELETE] },
      { action: 'edit', permissions: [UserPermissionsConstants.ROUTING_MANAGER_EDIT] },
      { action: 'view', permissions: [UserPermissionsConstants.ROUTING_MANAGER_VIEW] },
    ],
  },
  {
    name: 'Tags',
    options: [
      { action: 'delete', permissions: [UserPermissionsConstants.TAG_DELETE] },
      { action: 'edit', permissions: [UserPermissionsConstants.TAG_EDIT, UserPermissionsConstants.TAG_CREATE] },
      { action: 'view', permissions: [UserPermissionsConstants.TAG_VIEW] },
    ],
  },
  {
    name: 'Templates',
    options: [
      { action: 'delete', permissions: [UserPermissionsConstants.TEMPLATE_MESSAGE_DELETE] },
      { action: 'edit', permissions: [UserPermissionsConstants.TEMPLATE_MESSAGE_EDIT, UserPermissionsConstants.TEMPLATE_MESSAGE_CREATE] },
      { action: 'view', permissions: [UserPermissionsConstants.TEMPLATE_MESSAGE_VIEW] },
    ],
  },
];

export const messageActionsTemplate = [
  {
    name: 'Assign Message to Self',
    options: [
      { action: 'delete', permissions: [] },
      { action: 'edit', permissions: [UserPermissionsConstants.ASSIGNMENT_SELF_CREATE] },
      { action: 'view', permissions: [] },
    ],
  },
  {
    name: 'Assign Message to Group',
    options: [
      { action: 'delete', permissions: [] },
      { action: 'edit', permissions: [UserPermissionsConstants.ASSIGNMENT_GROUP_CREATE] },
      { action: 'view', permissions: [] },
    ],
  },
  {
    name: 'Assign Message to Member',
    options: [
      { action: 'delete', permissions: [] },
      { action: 'edit', permissions: [UserPermissionsConstants.ASSIGNMENT_MEMBER_CREATE] },
      { action: 'view', permissions: [] },
    ],
  },
  {
    name: 'Close Conversation',
    options: [
      { action: 'delete', permissions: [] },
      { action: 'edit', permissions: [UserPermissionsConstants.CONVERSATION_CLOSE_DEFAULT] },
      { action: 'view', permissions: [] },
    ],
  },
  {
    name: 'Close Member Conversation',
    options: [
      { action: 'delete', permissions: [] },
      { action: 'edit', permissions: [UserPermissionsConstants.CONVERSATION_CLOSE_MEMBER] },
      { action: 'view', permissions: [] },
    ],
  },
  {
    name: 'Reassign Member Conversation',
    options: [
      { action: 'delete', permissions: [] },
      { action: 'edit', permissions: [UserPermissionsConstants.CONVERSATION_MEMBER_ASSIGNMENT_EDIT] },
      { action: 'view', permissions: [] },
    ],
  },
  {
    name: 'Contacts & Patient Conversations',
    options: [
      { action: 'delete', permissions: [UserPermissionsConstants.CONTACT_DELETE] },
      { action: 'edit',
        permissions: [
          UserPermissionsConstants.CONTACT_EDIT,
          UserPermissionsConstants.CONTACT_CREATE,
          UserPermissionsConstants.THREAD_HIPAA_CREATE,
          UserPermissionsConstants.THREAD_MESSAGE_CREATE,
          UserPermissionsConstants.THREAD_NOTE_CREATE,
          UserPermissionsConstants.THREAD_COMPLETE_EDIT,
          UserPermissionsConstants.THREAD_TEMPLATE_EDIT,
        ],
      },
      { action: 'view',
        permissions: [
          UserPermissionsConstants.THREAD_UNREAD_EDIT,
          UserPermissionsConstants.THREAD_FOLLOW_EDIT,
          UserPermissionsConstants.THREAD_VIEW,
          UserPermissionsConstants.CONTACT_VIEW,
        ],
      },
    ],
    mobile: [
      { action: 'Mobile Access', permissions: [UserPermissionsConstants.CONVERSATION_CONTACT_MOBILE] },
    ],
  },
  {
    name: 'RhinoBlast',
    options: [
      { action: 'delete', permissions: [] },
      { action: 'edit', permissions: [UserPermissionsConstants.BULK_MESSAGING_EDIT] },
      { action: 'view', permissions: [] },
    ],
  },
  {
    name: 'RhinoCall',
    options: [
      { action: 'delete', permissions: [] },
      { action: 'edit', permissions: [UserPermissionsConstants.CALL_EDIT] },
      { action: 'view', permissions: [] },
    ],
  },
  {
    name: 'RhinoVideo',
    options: [
      { action: 'delete', permissions: [] },
      { action: 'edit', permissions: [UserPermissionsConstants.VIDEO_EDIT] },
      { action: 'view', permissions: [] },
    ],
  },
  {
    name: 'Select & Save Content',
    options: [
      { action: 'delete', permissions: [UserPermissionsConstants.CONTENT_SELECT_AND_SAVE_DELETE] },
      { action: 'edit', permissions: [UserPermissionsConstants.CONTENT_SELECT_AND_SAVE_EDIT] },
      { action: 'view', permissions: [] },
    ],
  },
  {
    name: 'HIPAA Consent Status',
    options: [
      { action: 'delete', permissions: [] },
      { action: 'edit', permissions: [UserPermissionsConstants.CONTACT_HIPAA_CONSENT_EDIT] },
      { action: 'view', permissions: [] },
    ],
  },
  {
    name: 'Marketing Consent Status',
    options: [
      { action: 'delete', permissions: [] },
      { action: 'edit', permissions: [UserPermissionsConstants.CONTACT_MARKETING_CONSENT_EDIT] },
      { action: 'view', permissions: [] },
    ],
  },
  {
    name: 'RhinoPay Consent Status',
    options: [
      { action: 'delete', permissions: [] },
      { action: 'edit', permissions: [UserPermissionsConstants.CONTACT_RHINOPAY_CONSENT_EDIT] },
      { action: 'view', permissions: [] },
    ],
  },
  {
    name: 'Team Conversations',
    options: [
      { action: 'delete', permissions: [] },
      { action: 'edit',
        permissions: [
          UserPermissionsConstants.TEAM_CONVERSATION_EDIT,
        ],
      },
      { action: 'view',
        permissions: [
          UserPermissionsConstants.TEAM_THREAD_VIEW,
          UserPermissionsConstants.TEAM_THREAD_UNREAD_EDIT,
        ],
      },
    ],
    mobile: [
      { action: 'Mobile Access', permissions: [UserPermissionsConstants.CONVERSATION_TEAM_MOBILE] },
    ],
  },
  {
    name: 'Disable Emoji Send',
    options: [
      { action: 'delete', permissions: [] },
      { action: 'edit', permissions: [UserPermissionsConstants.EMOJI_SEND_DISABLE] },
      { action: 'view', permissions: [] },
    ],
  },
  /*
  NOTE: THESE PERMISSIONS EXIST IN RHINOAPI AND DB. THEY ARE BEING HIDDEN TO USERS UNTIL THE FEATURES ARE RELEASED ON THE FRONT-END.
  {
    name: 'Send to EHR/PMS',
    options: [
      { action: 'delete', permissions: [] },
      { action: 'edit', permissions: [UserPermissionsConstants.CONTENT_SEND_TO_EHR] },
      { action: 'view', permissions: [] },
    ],
  },
  {
    name: 'Share Internally',
    options: [
      { action: 'delete', permissions: [] },
      { action: 'edit', permissions: [UserPermissionsConstants.CONTENT_SHARE_WITH_MEMBERS] },
      { action: 'view', permissions: [] },
    ],
  }, */
];

export const miscellaneousActionsTemplate = [
  {
    name: 'Analytics Dashboard',
    options: [
      { action: 'delete', permissions: [] },
      { action: 'edit', permissions: [] },
      { action: 'view', permissions: [UserPermissionsConstants.ANALYTICS_VIEW] },
    ],
  },
];
