import { UtilitySystem } from 'rhinostyle';
import StorageService, { createCookie, readCookie } from '../services/StorageService';
import store from '../store';
import { isMobile } from './BrowserHelpers';
import { userHasAnyOfPermissions, userHasAllOfPermissions } from './UserHelpers';
import { alphabeticalByKey } from './DataHelpers';
import { getLoggedInUser, userHasLimitedProviderRole } from '../selectors/userSelectors';
import { getCurrentOrg } from '../selectors/organizationSelectors';
import { getIsNewFeatureTourVisible } from '../selectors/uiSelectors';
import * as UIReducer from '../reducers/uiReducer';
import {
  THREAD_VIEW,
  CONVERSATION_CONTACT_MOBILE,
  CONTACT_EDIT,
  CONTACT_DELETE,
  TEAM_THREAD_VIEW,
  TEAM_CONVERSATION_EDIT,
  CONVERSATION_TEAM_MOBILE,
} from '../constants/UserPermissionsConstants';

// This is copied from uiSelectors and used in Action files since selectors will not work there.  Keeping the
// selector as well since it should be more performant when using from components.
export function getTotalUnreadCount() { // eslint-disable-line
  const currentState = store.getState();
  const secureChannels = currentState.secure.channels;
  const secureChannelIds = currentState.secure.channelIds;
  const isLimitedProvider = userHasLimitedProviderRole(currentState);
  const { inboxSections } = currentState.ui;
  let totalCount = 0;
  const isPatientExperience = StorageService.readEntry('patientExperience');
  const currentUser = getLoggedInUser(currentState);
  if (isPatientExperience) { // for end users
    totalCount = secureChannelIds.reduce(
      (accumulator, currentValue) => accumulator + secureChannels[currentValue].unread,
      0,
    );
  } else { // for members
    const currentOrg = getCurrentOrg(currentState);
    const contactsPermissions = isMobile() ? (userHasAllOfPermissions([THREAD_VIEW, CONVERSATION_CONTACT_MOBILE]) ||
      userHasAllOfPermissions([CONTACT_EDIT, CONVERSATION_CONTACT_MOBILE]) ||
      userHasAllOfPermissions([CONTACT_DELETE, CONVERSATION_CONTACT_MOBILE])) :
      userHasAnyOfPermissions([THREAD_VIEW]);

    const teamPermissions = isMobile() ? (userHasAllOfPermissions([TEAM_THREAD_VIEW, CONVERSATION_TEAM_MOBILE]) ||
      userHasAllOfPermissions([TEAM_CONVERSATION_EDIT, CONVERSATION_TEAM_MOBILE])) :
      userHasAnyOfPermissions([TEAM_THREAD_VIEW]);

    let sectionInbox = inboxSections.inbox;
    let sectionChat = inboxSections.chat;
    const chatMentionPreference = true;
    const inboxMentionPreference = true;
    const shouldAdjustBadgeCount = currentOrg?.isBadgeCountPreferencesEnabled && !currentUser.preferences.isBadgeCountUpdatedOnNotification;
    if (currentUser && !currentUser.isCcr && shouldAdjustBadgeCount) {
      // Get current user's notification preferences
      const { inbox, chat, inboxAndChat } = currentUser.preferences;
      const inboxPreferences = inbox.concat(inboxAndChat);
      const chatPreferences = chat.concat(inboxAndChat);
      const devicePreference = isMobile() ? 'mobile' : 'desktop';
      // remove inboxes disabled in user's notification preferences from total count
      sectionInbox = inboxSections.inbox?.filter((section) => {
        const match = inboxPreferences.find((pref) => (
          pref.type === 'group'
            ? pref.userGroupId === section.userGroupId
            : pref.type === section.type
        ));
        return match?.[devicePreference];
      });
      sectionChat = inboxSections.chat?.filter((section) => {
        const sectionBadgePreference = chatPreferences.find((pref) => (
          pref.type === 'group'
            ? pref.userGroupId === section.userGroupId
            : pref.type === section.type
        ));
        return sectionBadgePreference?.[devicePreference];
      });
    }

    if (isLimitedProvider) {
      sectionInbox = sectionInbox?.filter((section) => ['assigned', 'mentions'].includes(section.type));
      sectionChat = sectionChat?.filter((section) => ['direct'].includes(section.type));
    }

    const inboxCount = contactsPermissions && inboxSections.inbox && sectionInbox.reduce(
      (accumulator, currentValue) => accumulator + (currentValue.type === 'mentions' ?
        getTotalMentionsCount(currentValue, chatMentionPreference, inboxMentionPreference, shouldAdjustBadgeCount) :
        currentValue.unreadUserIds.length),
      0,
    );

    const chatCount = teamPermissions && inboxSections.chat && sectionChat.reduce(
      // eslint-disable-next-line max-len
      (accumulator, currentValue) => accumulator + (currentValue.unreadUserIds?.length ?? 0) + (currentValue.unreadGroupIds?.length ?? 0),
      0,
    );
    totalCount = inboxCount + chatCount;
  }
  return totalCount > 99999 ? 99999 : totalCount;
}

/**
 * @description determines whether or not an unread-message-count badge should
 * be displayed for a specific group based on a user's notification preferences
 * @param {string} section must be 'inbox', 'chat' or 'inboxAndChat'
 * @param {object} group
 * @return {boolean} true or false
 */
export function isBadgeCountVisible(section, group) {
  const currentState = store.getState();
  const currentUser = getLoggedInUser(currentState);
  const currentOrg = getCurrentOrg(currentState);
  if (!currentUser || currentUser?.isCcr || !currentUser?.preferences) return false;
  const { isBadgeCountUpdatedOnNotification, inbox, chat, inboxAndChat } = currentUser.preferences;
  const { isBadgeCountPreferencesEnabled } = currentOrg;
  if (isBadgeCountPreferencesEnabled && !isBadgeCountUpdatedOnNotification) {
    const inboxPreferences = inbox.map((pref) => ({ ...pref, section: 'inbox' }));
    const chatPreferences = chat.map((pref) => ({ ...pref, section: 'chat' }));
    const inboxAndChatPreferences = inboxAndChat.map((pref) => ({ ...pref, section: 'inboxAndChat' }));
    const notificationPreferences = inboxPreferences.concat(chatPreferences, inboxAndChatPreferences);
    const badgeCountPreference = notificationPreferences.find((p) => p.displayName === group.displayName && (p.section === section || p.section === 'inboxAndChat'));
    return isMobile() ? badgeCountPreference?.mobile : badgeCountPreference?.desktop;
  }
  return true;
}

/**
 * @description conditionally sorts groups array alphabetically according to member preferences
 * @param {object[]} groups
 * @param {string} section must be 'inbox' or 'chat'
 * @return {object[]} groups array, sorted or unsorted
 */
export function sortGroupsByPref(groups, section) {
  const currentState = store.getState();
  const currentUser = getLoggedInUser(currentState);

  if (currentUser && !currentUser.isCcr) {
    // MEMBER
    const { isInboxSortedAz, isChatSortedAz } = currentUser.preferences;

    const key = 'displayName';
    const pref = section === 'inbox' ? isInboxSortedAz : isChatSortedAz;
    const groupDirect = groups.find((g) => g.type === 'direct');

    const sortedGroups = groups
      .filter((g) => g !== groupDirect)
      .sort(alphabeticalByKey(key));

    sortedGroups.unshift(groupDirect);

    return pref ? sortedGroups : groups;
  } else {
    // CCR
    return groups;
  }
}

export const panelBreakpointMin = () => window.matchMedia(`(min-width: ${UtilitySystem.config.breakpoints.large})`).matches;
export const panelBreakpointMax = () => window.matchMedia(`(max-width: ${UtilitySystem.config.breakpoints.largeMax})`).matches;

// New Feature Tours

export function createNewFeatureTourCookie(feature) {
  const currentState = store.getState();
  const currentUser = getLoggedInUser(currentState);
  if (currentUser && !currentUser.isCcr) {
    createCookie(`rhinogram-${currentUser.id}-newFeature-${feature}`, 1, 100);
    store.dispatch(UIReducer.disableNewFeatureTourVisibility(feature));
  }
}

export function lookupNewFeatureTourCookie(feature) {
  const currentState = store.getState();
  const currentUser = getLoggedInUser(currentState);
  if (currentUser && !currentUser.isCcr) {
    const isFeatureTourDismissed = Number(readCookie(`rhinogram-${currentUser.id}-newFeature-${feature}`)) === 1;
    const isCypressTestRunning = readCookie('cypress-disable-new-feature-tour') === 'disable';
    const isNewFeatureTourDisabled = isFeatureTourDismissed || isCypressTestRunning;
    if (!isNewFeatureTourDisabled) {
      store.dispatch(UIReducer.enableNewFeatureTourVisibility(feature));
    } else {
      store.dispatch(UIReducer.disableNewFeatureTourVisibility(feature));
    }
  } else {
    store.dispatch(UIReducer.disableNewFeatureTourVisibility(feature));
  }
}

export function handleDismissNewFeatureTour(feature) {
  const currentState = store.getState();
  const isNewFeatureTourVisible = getIsNewFeatureTourVisible(currentState);
  if (isNewFeatureTourVisible[feature]) {
    createNewFeatureTourCookie(feature);
  }
}

export function getMentionBadgeCount(mentionsSection) {
  const shouldShowChatMentionBadge = isBadgeCountVisible('chat', mentionsSection);
  const shouldShowInboxMentionBadge = isBadgeCountVisible('inbox', mentionsSection);
  const totalMentionCount = getAdjustedMentionsCount(mentionsSection, shouldShowChatMentionBadge, shouldShowInboxMentionBadge);
  return totalMentionCount;
}

export function getTotalMentionsCount(mentionsSection, chatMentionPreference, inboxMentionPreference, shouldAdjustBadgeCount) {
  const shouldShowChatMentionBadge = shouldAdjustBadgeCount ? chatMentionPreference : true;
  const shouldShowInboxMentionBadge = shouldAdjustBadgeCount ? inboxMentionPreference : true;
  const totalMentionCount = getAdjustedMentionsCount(mentionsSection, shouldShowChatMentionBadge, shouldShowInboxMentionBadge);
  return totalMentionCount;
}

function getAdjustedMentionsCount(mentionsSection, shouldShowChatMentionBadge, shouldShowInboxMentionBadge) {
  const chatMentionCount = !shouldShowChatMentionBadge ? 0 : mentionsSection.unreadChatMentionIds?.length;
  const inboxMentionCount = !shouldShowInboxMentionBadge ? 0 : mentionsSection.unreadInboxMentions?.length;
  return chatMentionCount + inboxMentionCount;
}
