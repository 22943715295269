import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  Checkbox,
  CheckboxGroup,
  Alert,
  Tooltip,
  Icon,
  RhinoSwitch,
} from 'rhinostyle';
import { RHINOFORMS_ADMIN } from '../constants/UserPermissionsConstants';

const renderOrganizationActionsForRhinoForms = (option, isSystemRole, handleCheckboxClick) => (
  <>
    {option.action === 'view' ? (
      <td className="rhinoform-view" colSpan={3} key={option.action}>
        <Checkbox
          disabled={isSystemRole}
          type="secondary"
          isChecked={option.value}
          onChange={(key, value) => handleCheckboxClick(option.permissions, value)}
          name={option.action}
          label="&#8203;"
        />
      </td>
    ) : null}
  </>
);

const renderOrganizationActionsForIntegratedContacts = (option, isSystemRole, handleCheckboxClick, activeOrg) => (
  <>
    {option.action === 'edit' ? (
      <td className="integrated-contact-view" colSpan={3} key={option.action}>
        <Checkbox
          disabled={isSystemRole || !activeOrg.isEditIntegratedContactEnabled}
          type="secondary"
          isChecked={option.value}
          onChange={(key, value) => handleCheckboxClick(option.permissions, value)}
          name={option.action}
          label="&#8203;"
        />
      </td>
    ) : null}
  </>
);

const organizationActions = (option, setting, roleName, isSystemRole, handleCheckboxClick, activeOrg) => {
  if (setting.name === 'RhinoForms' && roleName !== RHINOFORMS_ADMIN) {
    return renderOrganizationActionsForRhinoForms(option, isSystemRole, handleCheckboxClick);
  }
  if (setting.name === 'Integrated Contacts') {
    return renderOrganizationActionsForIntegratedContacts(option, isSystemRole, handleCheckboxClick, activeOrg);
  }
  return (
    <td key={option.action}>
      {option.permissions.length > 0 ? (
        <CheckboxGroup>
          <Checkbox
            disabled={isSystemRole}
            type="secondary"
            isChecked={option.value}
            onChange={(key, value) => handleCheckboxClick(option.permissions, value)}
            name={option.action}
            label="&#8203;"
          />
        </CheckboxGroup>
      ) : (
        <div />
      )}
    </td>
  );
};

const renderPermissionTooltip = (setting) => {
  if (setting.name === 'Members') {
    return (
      <div className="u-m-l-small u-inline-block">
        <Tooltip
          content="Members can only be deactivated. Currently deleting Members is not supported."
          type="dark"
          placement="bottom"
        >
          <Icon icon="info-circle" />
        </Tooltip>
      </div>

    );
  } if (setting.name === 'Close Member Conversation') {
    return (
      <div className="u-m-l-small u-inline-block">
        <Tooltip
          content="Allows user to close conversation assigned to another member."
          type="dark"
          placement="bottom"
        >
          <Icon icon="info-circle" />
        </Tooltip>
      </div>
    );
  } if (setting.name === 'Disable Emoji Send') {
    return (
      <div className="u-m-l-small u-inline-block">
        <Tooltip
          content="Hides the custom Emoji picker inside of Rhinogram. This does not affect the native emoji picker on a mobile device."
          type="dark"
          placement="bottom"
        >
          <Icon icon="info-circle" />
        </Tooltip>
      </div>
    );
  }
  return null;
};

const OrganizationRolePermissions = React.memo((props) => {
  const { activeOrg } = props;
  const renderOrganizationActions = (permissions, handleCheckboxClick, isSystemRole, roleName) =>
    permissions.organizationActions.map((setting) => (!setting?.visible || setting?.visible(props)) && (
      <tr key={setting.name}>
        <>
          <td>
            <strong>{setting.name}{renderPermissionTooltip(setting)}
            </strong>
          </td>
          {setting.options.map((option) => (
            <Fragment key={option.action}>
              {organizationActions(option, setting, roleName, isSystemRole, handleCheckboxClick, activeOrg)}
            </Fragment>
          ))}
        </>
      </tr>
    ));

  const renderToggleFields = (setting, handlePermissionChange, isMobilePermissionVisibleforTeam, isMobilePermissionVisibleforContact, isSystemRole) => {
    const panelVisibleForContactsConversation = isMobilePermissionVisibleforContact && setting.permissions[0] === 'CONVERSATION_CONTACT_MOBILE';
    const panelVisibleForTeamConversation = isMobilePermissionVisibleforTeam && setting.permissions[0] === 'CONVERSATION_TEAM_MOBILE';
    if (panelVisibleForContactsConversation || panelVisibleForTeamConversation) {
      return (
        <tr className="mobile-toggle">
          <td><strong>{setting.action}</strong></td>
          <td colSpan="3">
            <RhinoSwitch
              disabled={isSystemRole}
              name={setting.permissions[0]}
              isChecked={setting.value}
              onChange={(key, value) => handlePermissionChange(setting.permissions[0], value)}
            />
          </td>
        </tr>
      );
    }
    return null;
  };

  // eslint-disable-next-line max-len
  const renderMessageActions = (permissions, handleCheckboxClick, isSystemRole, handlePermissionChange, isMobilePermissionVisibleforTeam, isMobilePermissionVisibleforContact) => permissions.messageActions.map((setting) => (
    <Fragment key={setting.name}>
      <tr>
        <td>
          <strong>{setting.name}{renderPermissionTooltip(setting)}
          </strong>
        </td>
        {setting.options.map((option) => (
          <td key={option.action}>
            {option.permissions.length > 0 ? (
              <CheckboxGroup>
                <Checkbox
                  disabled={isSystemRole}
                  type="secondary"
                  isChecked={option.value}
                  onChange={(key, value) => handleCheckboxClick(option.permissions, value)}
                  name={option.action}
                  label="&#8203;"
                />
              </CheckboxGroup>
            ) : (
              <div />
            )}
          </td>
        ))}
      </tr>
      {setting.mobile && renderToggleFields(
        setting.mobile[0],
        handlePermissionChange,
        isMobilePermissionVisibleforTeam,
        isMobilePermissionVisibleforContact,
        isSystemRole,
      )}
    </Fragment>
  ));

  const renderMiscellaneousActions = (permissions, handleCheckboxClick, isSystemRole) => permissions.miscellaneousActions.map((setting) => (
    <tr key={setting.name}>
      <>
        <td>
          <strong>{setting.name}</strong>
        </td>
        {setting.options.map((option) => (
          <td key={option.action}>
            {option.permissions.length > 0 ? (
              <CheckboxGroup>
                <Checkbox
                  disabled={isSystemRole}
                  type="secondary"
                  isChecked={option.value}
                  onChange={(key, value) => handleCheckboxClick(option.permissions, value)}
                  name={option.action}
                  label="&#8203;"
                />
              </CheckboxGroup>
            ) : (
              <div />
            )}
          </td>
        ))}
      </>
    </tr>
  ));

  const renderSettingsActionsTableHeader = () => (
    <thead>
      <tr>
        <th>ORGANIZATION SETTINGS ACTIONS</th>
        <th>
          Delete
        </th>
        <th>
          Edit
        </th>
        <th>
          View
        </th>
      </tr>
    </thead>
  );

  const renderMessageActionsTableHeader = () => (
    <thead>
      <tr>
        <th>CONVERSATION THREAD ACTIVITY</th>
        <th>
          Delete
        </th>
        <th>
          Edit
        </th>
        <th>
          View
        </th>
      </tr>
    </thead>
  );

  const renderMiscellaneousActionsTableHeader = () => (
    <thead>
      <tr>
        <th>MISCELLANEOUS ACTIONS</th>
        <th>
          Delete
        </th>
        <th>
          Edit
        </th>
        <th>
          View
        </th>
      </tr>
    </thead>
  );

  return (
  /**
    * NOTE: Since this component is called from a parent stateless component, destructuring 'props' results in an eslint error
    * due to the no-shadow rule, that states the variable is already declared in the upper scope.
    *
    * RULE DETAILS HERE: https://eslint.org/docs/2.0.0/rules/no-shadow
    *
    * By NOT destructuring, we leverage all data and methods directly off the props object thus avoiding the duplicate declaration.
    *
    * See SECTION 3 of top answer on this SO issue:
    * https://stackoverflow.com/questions/37682705/avoid-no-shadow-eslint-error-with-mapdispatchtoprops
    * */

    <>
      <div className="box__title-wrapper form__group">
        <div name="permissions" className="box__title">PERMISSIONS</div>
        {props.errors.permissions && (
          <Alert type="danger" className="u-m-t">
            {props.errors.permissions}
          </Alert>
        )}
      </div>

      <div className="permissions__settings-table-container">
        <table className="table permissions__settings-table">
          {renderSettingsActionsTableHeader()}
          <tbody>
            {renderOrganizationActions(props.permissions, props.handleCheckboxClick, props.isSystemRole, props.roleName)}
          </tbody>
        </table>
      </div>
      <div className="permissions__settings-table-container">
        <table className="table permissions__settings-table">
          {renderMessageActionsTableHeader()}
          <tbody>
            {renderMessageActions(
              props.permissions,
              props.handleCheckboxClick,
              props.isSystemRole,
              props.handlePermissionChange,
              props.isMobilePermissionVisibleforTeam,
              props.isMobilePermissionVisibleforContact,
            )}
          </tbody>
        </table>
      </div>
      <div className="permissions__settings-table-container">
        <table className="table permissions__settings-table">
          {renderMiscellaneousActionsTableHeader()}
          <tbody>
            {renderMiscellaneousActions(props.permissions, props.handleCheckboxClick, props.isSystemRole)}
          </tbody>
        </table>
      </div>
    </>
  );
});

OrganizationRolePermissions.propTypes = {
  errors: PropTypes.object,
  handleCheckboxClick: PropTypes.func.isRequired,
  handlePermissionChange: PropTypes.func.isRequired,
  isMobilePermissionVisibleforContact: PropTypes.bool,
  isMobilePermissionVisibleforTeam: PropTypes.bool,
  isSystemRole: PropTypes.bool,
  permissions: PropTypes.object.isRequired,
  roleName: PropTypes.string,
  activeOrg: PropTypes.object,
};

export default OrganizationRolePermissions;
