import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';

import Header from '../components/Header';
import LimiteProviderHeader from '../components/LimitedProviderHeader';
import * as AuthReducer from '../reducers/authReducer';
import * as LocationHelpers from '../helpers/LocationHelpers';
import * as ImageHelpers from '../helpers/ImageHelpers';
import OneSignalService from '../services/OneSignalService';
import WebSocketService from '../services/WebSocketService';
import OutboundPostMessageService from '../services/OutboundPostMessageService';
import { UIHelpers } from '../helpers';
import { getLoggedInUser, userHasLimitedProviderRole } from '../selectors/userSelectors';
import { getCurrentOrg } from '../selectors/organizationSelectors';
import Panels from '../helpers/PanelHelpers';
import { TYPE_INTEGRATION_AXIUM } from '../constants/Types';

class HeaderContainer extends React.Component {
  animationFrame = null; //eslint-disable-line
  hidden = false;
  visibilityChange = null;
  headerRef = React.createRef();
  state = {
    showGlobalSearchModalWrapper: false,
    // userHasBillingPermissions: userHasAnyOfPermissions([BILLING_VIEW]),
  };

  componentDidMount() {
    // Page visibility code
    if (typeof document.hidden !== 'undefined') { // Opera 12.10 and Firefox 18 and later support
      this.hidden = 'hidden';
      this.visibilityChange = 'visibilitychange';
    } else if (typeof document.msHidden !== 'undefined') {
      this.hidden = 'msHidden';
      this.visibilityChange = 'msvisibilitychange';
    } else if (typeof document.webkitHidden !== 'undefined') {
      this.hidden = 'webkitHidden';
      this.visibilityChange = 'webkitvisibilitychange';
    }
    this.history = this.props.history;
    // Handle page visibility change
    document.addEventListener(this.visibilityChange, this.handleVisibilityChange, false);
  }

  componentDidUpdate(prevProps) {
    // Fixes an issue with our logout function getting caught in a loop thanks to one of our interceptors.
    // RHIN-5062
    if (this.props.logoutInProgress) {
      this.props.logout(this.props.logoutType, this.props?.currentUser?.id)
        .then((response) => {
          if (response === 'legacy') {
            this.props.history.push('/login?legacy=true');
            this.props.clearAuth();
          }
        });
    } if (this.props.totalUnreadCount !== prevProps.totalUnreadCount) {
      OutboundPostMessageService.postMessage({
        type: 'setBadgeCount',
        data: { badgeCount: this.props.totalUnreadCount },
      });
    }
  }

  componentWillUnmount() {
    document.removeEventListener(this.visibilityChange, this.handleVisibilityChange, false);
  }

  handleToggleGlobalSearchModalWrapper = () => {
    this.setState({ showGlobalSearchModalWrapper: !this.state.showGlobalSearchModalWrapper });
  }

  handleVisibilityChange = () => {
    if (document[this.hidden]) { // if tab is out of focus, then listen for notifications
      OneSignalService.subscribe();
      WebSocketService.setOfflinePresence(); // idle/offline
    } else { // if in focus, then stop listening for notifications
      OneSignalService.unsubscribe();
      WebSocketService.setOnlinePresence(this.history); // online
    }
  }

  handleLogout = () => {
    cancelAnimationFrame(this.animationFrame);

    this.props.handleSetLogoutInProgress();
  };

  handleAnalyticsClick = () => {
    const startDate = moment().subtract(30, 'days').format('YYYY-MM-DD');
    const endDate = moment().subtract(1, 'days').format('YYYY-MM-DD');
    this.props.history.push(`/analytics?from=${startDate}&to=${endDate}&activeKey=3`);
  };

  toggleNavigation = () => {
    Panels.toggleNav();
  };

  toggleProfile = () => {
    Panels.toggleSecureProfile();
  };

  render() {
    const totalUnread = this.props.totalUnreadCount;
    ImageHelpers.updateFavicon(totalUnread);
    const title = LocationHelpers.getPageTitle(window.location.pathname);
    const activeSettingsPath = window.location.pathname;
    const documentTitle = this.props.currentOrganization?.integrationPartnerTypeId === TYPE_INTEGRATION_AXIUM ? 'RhinogramU' : 'Rhinogram';
    document.title = totalUnread ? `(${totalUnread}) ${documentTitle} | ${title}` : `${documentTitle} | ${title}`;
    const props = {
      activeSettingsPath,
      billing: this.props.billing,
      currentUser: this.props.currentUser,
      currentOrganization: this.props.currentOrganization,
      isNativeApp: this.props.isNativeApp,
      handleEditProfileClick: this.handleEditProfileClick,
      handleAnalyticsClick: this.handleAnalyticsClick,
      handleLogout: this.handleLogout,
      organizations: this.props.organizations,
      toggleNavigation: this.toggleNavigation,
      toggleProfile: this.toggleProfile,
      totalUnreadCount: this.props.totalUnreadCount,
      logoutInProgress: this.props.logoutInProgress,
      logoutType: this.props.logoutType,
      isAutomatedMessagesLinkVisible: this.props.currentOrganization?.supportsAppointmentReminders,
      isRhinopayEnabled: this.props.isRhinopayEnabled,
      isAppointmentManagerEnabled: this.props.currentOrganization?.isAppointmentManagerEnabled,
      showGlobalSearchModalWrapper: this.state.showGlobalSearchModalWrapper,
      handleToggleGlobalSearchModalWrapper: this.handleToggleGlobalSearchModalWrapper,
      headerRef: this.headerRef,
      location: this.props.location,
      isLimitedProvider: this.props.isLimitedProvider,
    };

    return this.props.isLimitedProvider ? <LimiteProviderHeader {...props} /> : (
      <Header {...props} />
    );
  }
}

HeaderContainer.propTypes = {
  currentUser: PropTypes.object,
  currentOrganization: PropTypes.object,
  organizations: PropTypes.object,
  logout: PropTypes.func.isRequired,
  totalUnreadCount: PropTypes.number.isRequired,
  users: PropTypes.object,
  billing: PropTypes.object,
  isRhinopayEnabled: PropTypes.bool,
  history: PropTypes.object,
  clearAuth: PropTypes.func.isRequired,
  logoutInProgress: PropTypes.bool,
  handleSetLogoutInProgress: PropTypes.func.isRequired,
  logoutType: PropTypes.string,
  isNativeApp: PropTypes.bool,
  isLimitedProvider: PropTypes.bool,
};

const mapStateToProps = (state) => {
  const { auth, ui, organization, billing, user, pay, nativeApp } = state;

  return {
    currentUser: getLoggedInUser(state),
    currentOrganization: getCurrentOrg(state),
    navigationOpen: ui.navigationOpen,
    totalUnreadCount: UIHelpers.getTotalUnreadCount(state),
    users: user.users,
    organizations: organization.organizations,
    billing: billing.billing,
    isRhinopayEnabled: pay.merchant.isRhinopayEnabled,
    showGlobalSearchModalWrapper: user.showGlobalSearchModalWrapper,
    logoutInProgress: auth.logoutInProgress,
    logoutType: auth.logoutType,
    isNativeApp: nativeApp.isNativeApp,
    isLimitedProvider: userHasLimitedProviderRole(state),
  };
};

const actions = {
  logout: AuthReducer.logout,
  clearAuth: AuthReducer.clearAuth,
  handleSetLogoutInProgress: AuthReducer.handleSetLogoutInProgress,
};

export default connect(mapStateToProps, actions)(HeaderContainer);
