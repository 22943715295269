import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import OneSignalService from '../services/OneSignalService';
import OutboundPostMessageService from '../services/OutboundPostMessageService';
import * as UserReducer from '../reducers/userReducer';
import UserPreferences from '../components/UserPreferences';
import unsavedChanges from '../components/UnsavedChangesHOC';
import PageLoader from '../components/PageLoader';
import { getLoggedInUser, userHasLimitedProviderRole } from '../selectors/userSelectors';
import { hasData } from '../helpers/DataHelpers';
import { getCurrentOrg } from '../selectors/organizationSelectors';

/**
 * OneSignal notification summary:
 * On save, if desktop notifications are enabled, register with OneSignal,
 * but set the subscription to "muted" so notifications aren't shown while
 * the app has focus.
 */
class UserPreferencesContainer extends Component {
  state = {
    allChatGroupsDesktopSelected: false,
    allChatGroupsMobileSelected: false,
    allInboxAndChatGroupsDesktopSelected: false,
    allInboxAndChatGroupsMobileSelected: false,
    allInboxGroupsDesktopSelected: false,
    allInboxGroupsMobileSelected: false,
    browserNotificationPermission: '',
    chatGroupsExpanded: false,
    formInProgress: false,
    inboxAndChatGroupsExpanded: false,
    inboxGroupsExpanded: false,
    isPushNotificationsEnabled: false, // Only true if registered and subscribed
    isPushNotificationsSupported: OneSignalService.isPushNotificationsSupported(),
    pageLoading: true,
    touchId: this.props.touchIdEnabled,
    userPreferences: {},
    highPriorityNotification: false,
    userSoundPreferencesEnabled: false,
    isBadgeCountUpdatedOnNotification: false,
  };

  componentDidMount() {
    this.props.fetchUser(this.props.user.id);

    if (this.state.isPushNotificationsSupported) {
      this.checkPushNotificationState();

      OneSignal.push(() => {
        OneSignal.on('notificationPermissionChange', this.handleNotificationPermissionChange, this);
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.pageLoading !== prevProps.pageLoading) {
      this.setState({ pageLoading: this.props.pageLoading }); // eslint-disable-line react/no-did-update-set-state

      if (hasData(this.props.user.preferences)) {
        const userPreferences = this.props.user.preferences;

        // On page load, auto expand groups if group has any notifications enabled
        const inboxGroupsExpanded = userPreferences.inbox
          .filter(this.isGroup)
          .some((userPref) => userPref.desktop || userPref.mobile);
        const chatGroupsExpanded = userPreferences.chat
          .filter(this.isGroup)
          .some((userPref) => userPref.desktop || userPref.mobile);
        const inboxAndChatGroupsExpanded = userPreferences.inboxAndChat
          .filter(this.isGroup)
          .some((userPref) => userPref.desktop || userPref.mobile);

        const { userSoundPreferencesEnabled, isBadgeCountUpdatedOnNotification, highPriorityNotification } = userPreferences;
        this.setState({ // eslint-disable-line react/no-did-update-set-state
          userPreferences,
          inboxGroupsExpanded,
          chatGroupsExpanded,
          inboxAndChatGroupsExpanded,
          highPriorityNotification,
          userSoundPreferencesEnabled,
          isBadgeCountUpdatedOnNotification,
        });
      }
    }
  }

  componentWillUnmount() {
    // Undocumented OneSignal function to remove event listener
    if (OneSignal && OneSignal.emitter && typeof OneSignal.emitter.off === 'function') {
      OneSignal.emitter.off('notificationPermissionChange', this.handleNotificationPermissionChange, this);
    }
  }

  handleNotificationPermissionChange = (permissionChange) => {
    const isPermissionGranted = permissionChange.to === 'granted';

    if (isPermissionGranted) {
      this.registerForNotifications();
    }

    this.setState({ browserNotificationPermission: permissionChange.to });
  };

  notifyNativeOfTouchIdChanged(touchIdEnabled) {
    OutboundPostMessageService.postMessage({
      type: 'touchIdToggled',
      data: { touchIdEnabled },
    });
  }

  checkPushNotificationState = () => {
    OneSignalService.getSubscriptionState()
      .then((notificationState) => this.setState(notificationState));
  };

  handleToggle = (name, value) => {
    this.setState({ [name]: value });
  };

  handleTogglePreferences = (name, value) => {
    const userPreferences = JSON.parse(JSON.stringify(this.state.userPreferences));
    userPreferences[name] = value;
    this.setState({ userPreferences, [name]: value });
  }

  handleUseRecommendedSettingsClick = () => {
    const userPreferences = JSON.parse(JSON.stringify(this.state.userPreferences));

    userPreferences.inbox = userPreferences.inbox.map(this.updateToRecommendedSetting);
    userPreferences.chat = userPreferences.chat.map(this.updateToRecommendedSetting);
    userPreferences.inboxAndChat = userPreferences.inboxAndChat.map(this.updateToRecommendedSetting);

    this.setState({
      userPreferences,
      allInboxGroupsMobileSelected: false,
      allInboxGroupsDesktopSelected: false,
      allChatGroupsMobileSelected: false,
      allChatGroupsDesktopSelected: false,
      allInboxAndChatGroupsMobileSelected: false,
      allInboxAndChatGroupsDesktopSelected: false,
    });
  };

  updateToRecommendedSetting(groupPref) {
    const groupPrefClone = { ...groupPref };
    const userPrefType = groupPrefClone.type;
    if (userPrefType === 'assigned') {
      groupPrefClone.desktop = true;
      groupPrefClone.mobile = true;
    } else if (userPrefType === 'following') {
      groupPrefClone.desktop = false;
      groupPrefClone.mobile = false;
    } else if (userPrefType === 'direct') {
      groupPrefClone.desktop = true;
      groupPrefClone.mobile = true;
    } else if (userPrefType === 'group') {
      groupPrefClone.desktop = false;
      groupPrefClone.mobile = false;
    } else if (userPrefType === 'apptCodifiedResponse') {
      groupPrefClone.desktop = true;
      groupPrefClone.mobile = true;
    } else if (userPrefType === 'mentions') {
      groupPrefClone.desktop = true;
      groupPrefClone.mobile = true;
    }

    return groupPrefClone;
  }

  handleAllGroupsToggle = (groupType, deviceType, name, value) => {
    const userPreferences = JSON.parse(JSON.stringify(this.state.userPreferences));

    userPreferences[groupType] = userPreferences[groupType].map((groupPref) => {
      const groupPrefClone = { ...groupPref };

      if (groupPrefClone.type === 'group') {
        groupPrefClone[deviceType] = value;
      }

      return groupPrefClone;
    });

    this.setState({ userPreferences, [name]: value });
  };

  handleGroupToggle = (deviceType, value, group) => {
    const userPreferences = JSON.parse(JSON.stringify(this.state.userPreferences));
    const { groupType } = group;

    userPreferences[groupType] = userPreferences[groupType].map((groupPref) => {
      const groupPrefClone = { ...groupPref };

      if (group.type === groupPref.type && group.userGroupId === groupPref.userGroupId) {
        groupPrefClone[deviceType] = value;
      }

      return groupPrefClone;
    });

    // Uncheck 'all groups' if toggling any single, non-static group
    const uncheckAllState =
      group.type === 'group' ? this.uncheckAllState(groupType, deviceType) : {};

    this.setState({ userPreferences, ...uncheckAllState });
  };

  /**
   * Returns state object to uncheck the corresponding 'all groups'
   * checkbox for a given inbox section and device type.
   */
  uncheckAllState(groupType, deviceType) {
    const state = {};

    if (groupType === 'inbox') {
      if (deviceType === 'mobile') {
        state.allInboxGroupsMobileSelected = false;
      } else if (deviceType === 'desktop') {
        state.allInboxGroupsDesktopSelected = false;
      }
    } else if (groupType === 'chat') {
      if (deviceType === 'mobile') {
        state.allChatGroupsMobileSelected = false;
      } else if (deviceType === 'desktop') {
        state.allChatGroupsDesktopSelected = false;
      }
    } else if (groupType === 'inboxAndChat') {
      if (deviceType === 'mobile') {
        state.allInboxAndChatGroupsMobileSelected = false;
      } else if (deviceType === 'desktop') {
        state.allInboxAndChatGroupsDesktopSelected = false;
      }
    }

    return state;
  }

  handleGroupExpandToggle = (name) => {
    this.setState({
      [name]: !this.state[name],
    });
  };

  handleUpdateUserPreferences = () => {
    if (this.props.touchIdEnabled !== this.state.touchId) {
      this.notifyNativeOfTouchIdChanged(this.state.touchId);
    }

    const payload = this.state.userPreferences;

    this.setState({ formInProgress: true });
    this.props
      .updateUserPreferences(this.props.user.id, payload)
      .then(() => {
        this.props.resetFormChanges();

        this.setState({ formInProgress: false });
      });

    // Prompt to allow notifications
    this.registerForNotifications();
    if (this.props.handleClosePreferences) {
      this.props.handleClosePreferences();
    }
  };

  registerForNotifications() {
    if (this.state.isPushNotificationsSupported && this.hasDesktopNotificationEnabled()) {
      const userId = this.props.user.id;
      const orgId = this.props.user.organization;
      OneSignalService.register();
      OneSignalService.unsubscribe();
      OneSignalService.sendTagsWeb(userId, orgId);
    }
  }

  // Returns true if at least one desktop notification is enabled
  hasDesktopNotificationEnabled() {
    const { userPreferences } = this.state;

    return (
      userPreferences.inbox.some((userPref) => userPref.desktop) ||
      userPreferences.chat.some((userPref) => userPref.desktop) ||
      userPreferences.inboxAndChat.some((userPref) => userPref.desktop)
    );
  }

  isGroup(userPref) {
    return userPref.type === 'group';
  }

  isNonDynamicGroup(userPref) {
    return userPref.type === 'group' && !userPref.isDynamic;
  }

  // "Groups" that users always have - Assigned To Me, Following, Direct, etc.
  isStaticGroup(userPref) {
    return !['group', 'apptCodifiedResponse'].includes(userPref.type);
  }

  isCodifiedAppointmentReminderResponseGroup(userPref) {
    return userPref.type === 'apptCodifiedResponse';
  }

  filterGroups(groupType, predicate) {
    return this.state.userPreferences[groupType].filter(predicate).map((userPref) => {
      const userPrefClone = { ...userPref };
      userPrefClone.groupType = groupType;
      return userPrefClone;
    });
  }

  render() {
    if (this.state.pageLoading) return <PageLoader />;
    const props = {
      allChatGroupsDesktopSelected: this.state.allChatGroupsDesktopSelected,
      allChatGroupsMobileSelected: this.state.allChatGroupsMobileSelected,
      allInboxAndChatGroupsDesktopSelected: this.state.allInboxAndChatGroupsDesktopSelected,
      allInboxAndChatGroupsMobileSelected: this.state.allInboxAndChatGroupsMobileSelected,
      allInboxGroupsDesktopSelected: this.state.allInboxGroupsDesktopSelected,
      allInboxGroupsMobileSelected: this.state.allInboxGroupsMobileSelected,
      browserNotificationPermission: this.state.browserNotificationPermission,
      chatGroups: this.filterGroups('chat', this.isNonDynamicGroup),
      chatGroupsExpanded: this.state.chatGroupsExpanded,
      chatStaticGroups: this.filterGroups('chat', this.isStaticGroup),
      formInProgress: this.state.formInProgress,
      handleFormChanges: this.props.handleFormChanges,
      handleAllGroupsToggle: this.handleAllGroupsToggle,
      handleGroupExpandToggle: this.handleGroupExpandToggle,
      handleGroupToggle: this.handleGroupToggle,
      handleToggle: this.handleToggle,
      handleTogglePreferences: this.handleTogglePreferences,
      handleUpdateUserPreferences: this.handleUpdateUserPreferences,
      handleUseRecommendedSettingsClick: this.handleUseRecommendedSettingsClick,
      highPriorityNotification: this.state.highPriorityNotification,
      userSoundPreferencesEnabled: this.state.userSoundPreferencesEnabled,
      isBadgeCountUpdatedOnNotification: this.state.isBadgeCountUpdatedOnNotification,
      inboxAndChatGroups: this.filterGroups('inboxAndChat', this.isGroup),
      inboxAndChatGroupsExpanded: this.state.inboxAndChatGroupsExpanded,
      inboxCodifiedAppointmentReminderResponseGroups: this.filterGroups('inbox', this.isCodifiedAppointmentReminderResponseGroup),
      inboxGroups: this.filterGroups('inbox', this.isGroup),
      inboxGroupsExpanded: this.state.inboxGroupsExpanded,
      inboxStaticGroups: this.filterGroups('inbox', this.isStaticGroup),
      isNativeApp: this.props.isNativeApp,
      isPushNotificationsSupported: this.state.isPushNotificationsSupported,
      touchIdEnabled: this.props.touchIdEnabled,
      touchIdSupported: this.props.touchIdSupported,
      isSoundPreferencesEnabled: this.props.currentOrganization.isSoundPreferencesEnabled,
      isBadgeCountPreferencesEnabled: this.props.currentOrganization.isBadgeCountPreferencesEnabled,
      isLimitedProvider: this.props.isLimitedProvider,
      handleClosePreferences: this.props.handleClosePreferences,
    };

    return <UserPreferences {...props} />;
  }
}

UserPreferencesContainer.propTypes = {
  fetchUser: PropTypes.func.isRequired,
  isNativeApp: PropTypes.bool.isRequired,
  currentOrganization: PropTypes.object.isRequired,
  pageLoading: PropTypes.bool.isRequired,
  touchIdEnabled: PropTypes.bool.isRequired,
  touchIdSupported: PropTypes.bool.isRequired,
  updateUserPreferences: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  handleFormChanges: PropTypes.func.isRequired,
  resetFormChanges: PropTypes.func.isRequired,
  isLimitedProvider: PropTypes.bool,
  handleClosePreferences: PropTypes.func,
};

const mapStateToProps = (state) => {
  const { user, nativeApp } = state;

  return {
    isNativeApp: nativeApp.isNativeApp,
    currentOrganization: getCurrentOrg(state),
    pageLoading: user.loading,
    touchIdEnabled: nativeApp.touchIdEnabled,
    touchIdSupported: nativeApp.touchIdSupported,
    user: getLoggedInUser(state),
    isLimitedProvider: userHasLimitedProviderRole(state),
  };
};

const actions = {
  updateUserPreferences: UserReducer.updateUserPreferences,
  fetchUser: UserReducer.fetchUser,
};

export default connect(mapStateToProps, actions)(unsavedChanges(UserPreferencesContainer));
