import PropTypes from 'prop-types';
import React, { useState } from 'react';
import {
  Button,
  Icon,
  Modal,
  ModalHeader,
  ModalBody,
} from 'rhinostyle';
import rhinoLogoReversed from '../assets/images/rhinogram-logo-reversed.svg';
import UserPreferencesContainer from '../containers/UserPreferencesContainer';
import { isMobile } from '../helpers/BrowserHelpers';

const Header = (props) => {
  const {
    currentUser,
    logoutInProgress,
    handleLogout,
    toggleNavigation,
    totalUnreadCount,
  } = props;

  const [showPreferences, setShowPreferences] = useState(false);

  const handleShowPreferences = () => {
    setShowPreferences(true);
  };

  const handleClosePreferences = () => {
    setShowPreferences(false);
  };

  const renderHeader = () => {
    // Keep this because of https://github.com/rhinogram/rhinofront/issues/2315
    if (!currentUser) return null;
    const today = new Date();
    const currentHour = today.getHours();
    let greeting;
    let name;

    if (currentHour < 12) {
      greeting = 'Good morning';
    } else if (currentHour < 18) {
      greeting = 'Good afternoon';
    } else {
      greeting = 'Good evening';
    }

    if (currentUser.preferredName) {
      name = `, ${currentUser.preferredName}`;
    } else if (currentUser.firstName) {
      name = `, ${currentUser.firstName}`;
    }

    greeting = `${greeting}${name}!`;

    return (
      <>
        <div className="app-header__logo"><img src={rhinoLogoReversed} alt="Rhinogram" /></div>
        <div className="app-header__right">
          {!isMobile() && (
            <span className="u-text-small u-text-muted">{greeting}</span>
          )}
          <Button
            title="Preferences"
            type="link"
            onClick={handleShowPreferences}
            className="app-header__icon"
            data-cypress="limited-provider-header__preferences"
          >
            <Icon icon="notification-settings" />
          </Button>
          <Button
            loading={logoutInProgress}
            title="Log Out"
            type="link"
            onClick={handleLogout}
            className="app-header__icon"
            data-cypress="logoutButton"
          >
            <Icon icon="log-out" />
          </Button>
        </div>
      </>
    );
  };

  return (
    <>
      <header className="app-header" style={{ zIndex: 1021 }}>
        <div id="app-header__menu" className="app-header__menu" onClick={toggleNavigation}>
          {totalUnreadCount ? <div className="app-header__menu__badge">{totalUnreadCount}</div> : null}
          <div className="app-header__menu__bar" />
          <div className="app-header__menu__bar" />
          <div className="app-header__menu__bar" />
        </div>
        {renderHeader()}
        <Modal open={showPreferences}>
          <ModalHeader onClose={handleClosePreferences} title="NOTIFICATIONS" />
          <ModalBody>
            <UserPreferencesContainer handleClosePreferences={handleClosePreferences} />
          </ModalBody>
        </Modal>
      </header>
    </>
  );
};

Header.propTypes = {
  currentUser: PropTypes.object,
  logoutInProgress: PropTypes.bool,
  handleLogout: PropTypes.func,
  toggleNavigation: PropTypes.func,
  totalUnreadCount: PropTypes.number,
};

export default Header;
